import * as Sentry from "@sentry/browser";

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    ignoreErrors: [
      /ResizeObserver loop limit exceeded/,
      /ResizeObserver loop completed with undelivered notifications/
    ],
  });
  Sentry.configureScope((scope) => {
    scope.setTag("version", BUILD_VERSION);
    scope.setTag("build", BUILD_HASH);
  });
}
